<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce'

export default {
  name: "Details",
  components: { 'tinymceEditor': Editor },
  mixins: [tinymce],
  data: function () {
    return {
      user: null,
      showPassword: false,
      account: {
        id: 0,
        name: '',
        mailbox: false,
        forwarding: {
          enabled: false,
          addresses: []
        },
        autoresponder: {
          enabled: false,
          forward: '',
          subject: '',
          text: '',
        }
      },
    }
  },
  created: function(){
    let self = this;
    self.user = self.$store.getters.getUser;
    if(parseInt(self.$route.params.id) > 0){
      Http.request('GET', '/user/emails/' + self.$route.params.id).then((account) => {
        self.account = account.data;
      });
    }
  },
  methods: {
    emailValidator(value){
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([üöäÜÖÄa-zA-Z\-0-9]+\.)+[üöäÜÖÄa-zA-Z]{2,}))$/;
      let tonlinePattern = /^.*@(t\-online\.de|magenta\.de)$/i;
      return pattern.test(String(value)) && !tonlinePattern.test(String(value));
    },
    save(){
      let self = this;
      Http.request('POST', '/user/emails/' + (self.$route.params.id > 0 ? self.$route.params.id : ''), self.account).then(() => {
        self.$router.push('/data/settings/emails');
      });
    },
    deleteAccount: function () {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diesen Account wirklich löschen? Alle E-Mails in diesem Account gehen dabei verloren!', {
          headerBgVariant: 'primary',
          title: 'Account löschen?',
          okTitle: 'Ja alle E-Mails löschen',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/user/emails/' + self.$route.params.id).then(() => {
              self.$router.push('/data/settings/emails');
            });
          }
        });
    },
  }
}
</script>

<style scoped></style>
